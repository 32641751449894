import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { formatDate } from "../../../utility/DateFormatter";
import { Battle } from "../../../models/Battle";
import { User } from "../../../models/User";
import PlayerSongCard from "./PlayerSongCard";
import SocialShare from './SocialShare';

const url = `${window.location.origin}${location.pathname}`;
console.log(url);
const title = 'Check out this Awesome Guitar Battle!';

export type BattleCardProps = {
  battle: Battle;
  type: string;
  onBattleClick: (battle: Battle) => void;
  currentUserId: string | undefined; // Accept currentUserId as a prop
};

const BattleCard: FunctionComponent<BattleCardProps> = ({
  battle,
  type,
  onBattleClick,
  currentUserId, // Destructure currentUserId
}) => {

  const startingOpponentIsWinner = (battle: Battle): boolean => {
    return battle.startingOpponentLikes > battle.opponentLikes;
  };

  const isUserInBattle = currentUserId !== undefined &&
    (Number(currentUserId) === battle.startingOpponent?.user.id ||
      Number(currentUserId) === battle.opponent?.user.id);

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="card-songs card-songs-listing d-flex flex-column h-100 rounded-5" onClick={() => onBattleClick(battle)}>
        <div className="d-flex flex-column gap-3 p-3 p-md-3">
          {battle.endedAt != null && (
            <div className="d-flex">
              <div className="text-capitalize bg-white rounded-pill px-3 py-2 d-inline-flex bg-opacity-50">
                <small>Ends: {formatDate(new Date(battle.endedAt))}</small>
              </div>
            </div>
          )}
          {/* Player cards */}
          <div className="d-flex flex-column text-black">
            <div className="d-flex gap-3 bg-white rounded-top-4 p-3 border">
              <PlayerSongCard
                user={battle.startingOpponent?.user}
                likes={battle.startingOpponent?.likes || 0}
                isWinner={startingOpponentIsWinner(battle)}
                video={battle.startingOpponent?.video}
                type={type}
              />
            </div>

            <div className="d-flex gap-3 bg-white rounded-bottom-4 p-3 border-end border-bottom border-start">
              <PlayerSongCard
                user={battle.opponent?.user}
                likes={battle.opponent?.likes || 0}
                isWinner={!startingOpponentIsWinner(battle)}
                video={battle.opponent?.video}
                type={type}
              />
            </div>
          </div>

          <div className="d-flex gap-3 flex-column flex-md-row justify-content-between align-items-end align-items-md-center flex-wrap">
            {isUserInBattle && (
              <div className="d-flex justify-content-between align-items-center gap-2 show-if-user-in-battle flex-wrap">
                <p className="mb-0">Share to win</p>
                <SocialShare url={url} title={title} />
              </div>
            )}
            <div className="bttn text-center ms-auto">View &amp; vote</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BattleCard;
