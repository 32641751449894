import { FunctionComponent, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import LargeBattleCard from "./components/LargeBattleCard";
import { getSingleBattle, addLikeToBattle } from "../../api/battles";
import { Battle } from "../../models/Battle";
import SocialShare from './components/SocialShare';
import Cookies from "js-cookie";

const SingleBattlePage: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const battleId = Number(id);
  const isLiked = isBattleLiked(battleId);
  const [battle, setBattle] = useState<Battle | null>(null);
  const [loading, setLoading] = useState(true);

  const url = `${window.location.origin}${location.pathname}`;
  console.log(url);
  const title = 'Check out this Awesome Guitar Battle!';

  const fetchSingleBattle = async (battleId: number) => {
    try {
      const result = await getSingleBattle(battleId);
      setBattle(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleBattle(battleId);
  }, [battleId]);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
  }, []);

  const onLikeClick = async (playerOrder: number) => {
    setLoading(true);
    try {
      if (!isLiked && battle !== null) {
        const result = await addLikeToBattle(battle.id, playerOrder);
        setBattle(result);
        handleLikeBattle(battleId, playerOrder);
      }
      setLoading(false);
    } catch (error) {
      console.error("Sending like failed:", error);
      setLoading(false);
    }
  };

  function handleLikeBattle(battleId: number, playerOrder: number) {
    // Set a cookie to store that the user liked this post
    Cookies.set(`liked_gbc_${battleId}`, "true", { expires: 365 });

    // Set cookies to store that the user liked each player in the battle
    Cookies.set(`liked_gbc_${battleId}_player${playerOrder}`, "true", {
      expires: 365,
    });
  }

  function isBattleLiked(battleId: number): boolean {
    // Check if the cookie exists for this post
    return Cookies.get(`liked_gbc_${battleId}`) === "true";
  }

  function convertLocalDateToUTCIgnoringTimezone(date: Date) {
    const timestamp = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds(),
        new Date().getUTCMilliseconds()
      )
    );

    return new Date(timestamp);
  }

  function isPlayerLiked(battleId: number, playerOrder: number): boolean {
    // Check if the cookie exists for this battle and player order
    return Cookies.get(`liked_gbc_${battleId}_player${playerOrder}`) === "true";
  }

  function calculateTimeLeftText(endDate: Date): string | null {
    if (!endDate) {
      return "";
    }
    // Set the time to the end of the day (23:59:59.999)
    const endOfDay = new Date(endDate);
    endOfDay.setHours(23, 59, 59, 999);

    const difference =
      endOfDay.getTime() -
      convertLocalDateToUTCIgnoringTimezone(new Date()).getTime();
    if (difference <= 0) {
      return "Voting has ended.";
    }

    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 0) {
      return `Voting ends in ${days} day${days > 1 ? "s" : ""}.`;
    } else if (hours > 0) {
      return `Voting ends in ${hours} hour${hours > 1 ? "s" : ""}.`;
    } else {
      return `Voting ends in ${minutes} minute${minutes > 1 ? "s" : ""}.`;
    }
  }

  return (
    <div>
      {battle && (
        <div>
          <div className="bg-yellow pt-5 pb-4">
            <div className="container-gbc pt-5 text-black">
              <div className="row text-left text-md-center pt-5">
                <div className="col-12">
                  <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                    <div className="d-flex fw-normal mb-2 flex-row text-center gap-2">
                      <div className="position-relative"><h1 className="fs-gbc-1 mb-0">Watch</h1> </div>
                      <div className="position-relative"><h1 className="fs-gbc-1 mb-0">Vote</h1> </div>
                      <div className="position-relative"><h1 className="fs-gbc-1 mb-0">Share</h1> </div>
                    </div>
                    <p className="fw-bold fs-gbc-2 mb-2">
                      {battle.status === "ongoing"
                        ? calculateTimeLeftText(battle.endedAt)
                        : "Voting has ended."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-green border-top">
            <div className="container-gbc py-4 py-md-5">
              <div className="row align-items-center">
                <LargeBattleCard
                  playerOrder={1}
                  playerData={battle.startingOpponent}
                  disabledLikeButton={isLiked || battle.status === "finished"}
                  isPlayerLiked={isPlayerLiked(battle.id, 1)}
                  onLikeClick={onLikeClick}
                />
                <LargeBattleCard
                  playerOrder={2}
                  playerData={battle.opponent}
                  disabledLikeButton={isLiked || battle.status === "finished"}
                  isPlayerLiked={isPlayerLiked(battle.id, 2)}
                  onLikeClick={onLikeClick}
                />
              </div>
            </div>
          </div>

          <div className="bg-yellow text-black border-top">
            <div className="container-gbc py-5">
              <div className="row text-center py-5">
                <div className="col-12">
                  <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                    <h2 className="fw-normal fs-gbc-1 mb-2">
                      More battles available
                    </h2>
                    <p className="fs-gbc-2 fw-lighter mb-5 col-md-8">
                      Check out these awesome battles and view the live ones.
                    </p>
                    <button className="bttn">
                      Back to battles list
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-card bg-yellow">
            <div className="container-gbc">

            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default SingleBattlePage;
