import React, { useState, useEffect } from "react";
import axios, { AxiosRequestConfig, CancelToken } from "axios";
import { useAuth } from "../../providers/AuthProvider";
import SuccessVideoUploadPopup from "./SuccessVideoUploadPopup";
import ErrorPopup from "../../views/components/ErrorPopup";
import api from "../../api/config/apiConfig";
import { Song } from "../../models/Song";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

// Define an interface for the component props
interface VideoProcessorProps {
  song: Song;
}

const VideoProcessor: React.FC<VideoProcessorProps> = ({ song }) => {
  const { authState } = useAuth();
  const { tokens } = authState;
  const [file, setFile] = useState<File | null>(null);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [overallProgress, setOverallProgress] = useState(0);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [fileSizeError, setFileSizeError] = useState<string | null>(null); // New state for file size error

  console.log(song);
  console.log(videoPreview);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (uploading) {
        event.preventDefault();
        event.returnValue = "Are you sure?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploading]);

  const handleUploadSuccess = () => {
    setShowSuccessPopup(false);
    setFile(null); // Reset selected file after successful upload
    setFileSizeError(null); // Reset file size error
  };

  const onCloseErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    if (selectedFile) {
      if (selectedFile.size > 1 * 1024 * 1024 * 1024) {
        // Check if file is larger than 1GB
        setFileSizeError(
          "File size exceeds 1GB. Please select a smaller file."
        );
        setFile(null);
        setVideoPreview(null);
      } else {
        setFileSizeError(null);
        setFile(selectedFile);
        setVideoPreview(URL.createObjectURL(selectedFile));
      }
    }
  };

  const uploadFileChunk = async (
    chunk: Blob,
    chunkIndex: number,
    totalChunks: number,
    setChunkProgress: (progress: number) => void,
    uploadId: string,
    cancelToken: CancelToken
  ) => {
    const formData = new FormData();
    formData.append("video", chunk);
    formData.append("chunkIndex", chunkIndex.toString());
    formData.append("totalChunks", totalChunks.toString());
    formData.append("songId", song.id);
    formData.append("uploadId", uploadId);

    const accessToken = tokens?.access_token || "";

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      timeout: 60000, // Set timeout to 60 seconds
      onUploadProgress: (progressEvent) => {
        const loaded = progressEvent.loaded;
        const total = progressEvent.total || 1;
        const progress = Math.round((loaded / total) * 100);
        setChunkProgress(progress);
      },
      cancelToken, // Pass cancelToken here
    };

    try {
      await api.post(`/video/upload-chunk`, formData, config);
    } catch (error) {
      console.error("Error uploading chunk:", error);
      throw error;
    }
  };

  const processAndUploadVideo = async () => {
    if (!file) return;

    // const uploadId = uuidv4();
    const uploadId = new Date().getTime().toString();

    setUploading(true);
    setShowSuccessPopup(false); // Reset success popup state

    const chunkSize = 10 * 1024 * 1024; // 10MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    const chunkProgress: number[] = Array(totalChunks).fill(0);

    const setChunkProgress = (index: number, progress: number) => {
      chunkProgress[index] = progress;
      const overallProgress =
        chunkProgress.reduce((a, b) => a + b, 0) / totalChunks;
      setOverallProgress(overallProgress);
      console.log(
        `Chunk ${index} progress: ${progress}%, Overall progress: ${overallProgress}%`
      );
    };

    const concurrentUploads = 5; // Limit the number of concurrent uploads
    let currentUploads = 0;

    // Create an AbortController instance
    const controller = new AbortController();
    const { signal } = controller;

    const uploadChunks = async () => {
      const uploadPromises: Promise<void>[] = [];
      const cancelTokenSource = axios.CancelToken.source(); // Create a CancelToken source

      for (let i = 0; i < totalChunks; i++) {
        while (currentUploads >= concurrentUploads) {
          // Wait until there is an available slot for uploading
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
        currentUploads++;
        const promise = uploadFileChunk(
          file.slice(i * chunkSize, (i + 1) * chunkSize),
          i,
          totalChunks,
          (progress) => setChunkProgress(i, progress),
          uploadId,
          cancelTokenSource.token
        )
          .finally(() => {
            currentUploads--;
          })
          .catch((error) => {
            console.error(`Error uploading chunk ${i}:`, error);
            // Optionally, handle or notify about the error
            cancelTokenSource.cancel("Aborting all uploads due to an error.");

            throw new Error("Upload interrupted due to an error.");
          });
        uploadPromises.push(promise);
      }

      // Wait for all upload promises to resolve
      // await Promise.all(uploadPromises);

      // Use Promise.allSettled to handle all promises
      const results = await Promise.allSettled(uploadPromises);

      // Check if any promise was rejected
      const anyFailed = results.some((result) => result.status === "rejected");
      if (anyFailed) {
        throw new Error("One or more uploads failed.");
      }

      console.log("All upload promises have resolved");
    };

    try {
      await uploadChunks();
      if (chunkProgress.every((progress) => progress === 100)) {
        setOverallProgress(100); // Ensure progress is set to 100% upon completion
        setShowSuccessPopup(true); // Show success popup only after all chunks are uploaded
      } else {
        console.log("Something went wrong, try again later");
      }
    } catch (error) {
      console.error("Error uploading video chunks:", error);
      setShowErrorPopup(true);
    } finally {
      setUploading(false);
      setOverallProgress(0); // Reset overall progress
    }
  };

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>
          {song?.songTitle
            ? `Upload video - ${song.songTitle} by ${song.artistName}`
            : "Upload video"}
        </title>
      </Helmet>

      {showErrorPopup && (
        <ErrorPopup
          onClose={onCloseErrorPopup}
          title="Error"
          message="Something went wrong with the upload. Try again later."
        />
      )}
      {showSuccessPopup && (
        <SuccessVideoUploadPopup onClose={handleUploadSuccess} />
      )}
      {fileSizeError && (
        <div style={{ color: "red", marginBottom: "10px" }}>
          {fileSizeError}
        </div> // Display file size error
      )}

      <div className="row">
        <div className="col-12 col-md-12">
          <div className="custom-upload d-flex flex-column-reverse flex-md-row gap-4 flex-wrap flex-md-nowrap w-100">
            <label htmlFor="upload-recorded-video">
              <div className="d-flex flex-column justify-content-between align-items-center gap-4 h-100">
                <div>
                  <p className="fs-4 mb-0 lh-sm text-center">
                    Drag and drop video file, or upload it from your device
                  </p>
                </div>
                <div className="px-5 py-3 bg-black rounded-5 text-white d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faVideo}
                    className="me-2"
                  />{" "}
                  Browse
                </div>
              </div>
              <input
                id="upload-recorded-video"
                type="file"
                accept="video/*"
                onChange={handleFileChange}
              />
            </label>
            {videoPreview && (
              <div className="text-end rounded-4 p-4 border bg-white">
                <video
                  controls
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "4px",
                  }}
                >
                  <source src={videoPreview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <button
                  className="bttn mt-3 position-relative z-3"
                  onClick={processAndUploadVideo}
                  disabled={!file || uploading}
                >
                  {uploading
                    ? `Uploading... ${Math.round(overallProgress)}%`
                    : "Upload Video"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoProcessor;
