import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

export type LearnSongsCardType = {
  id: string
  artistName?: string;
  songTitle?: string;
  level?: string;
  subLevel?: string
  videoUrl?: string;
  thumbnailUrl?: string;
  pdfUrl?: string;
  tips?: string;
  backingTrack?: string;
  isUserLesson?: boolean;
  hasUserVideo?: boolean;
  hasUserBattle?: boolean;
  action?: () => void;
};

const LearnSongsCard: FunctionComponent<LearnSongsCardType> = ({
  artistName,
  songTitle,
  level,
  subLevel,
  thumbnailUrl,
  isUserLesson,
  hasUserVideo,
  hasUserBattle,
  action
}) => {

  const buttonText = useMemo(() => {
    if (hasUserBattle) {
      return "Go to my battles";
    } else if (hasUserVideo) {
      return "Try again";
    } else if (isUserLesson) {
      return "Upload video";
    } else {
      return "Learn this song";
    }
  }, [isUserLesson, hasUserVideo, hasUserBattle]);

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="card-songs card-songs-listing d-flex flex-column h-100 rounded-5" onClick={action}>
        <div className="song-image">
          <div className="p-3 p-md-3">
            {isUserLesson ? <div className="fs-6 text-white">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="me-2"
              /> My Lesson</div> : null}
            {hasUserVideo ? <div className="fs-6 text-white">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="me-2"
              /> Recorded Video </div> : null}
            {hasUserBattle ? <div className="fs-6 text-white">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="me-2"
              /> In Battle</div> : null}
          </div>
          <img crossOrigin="anonymous" src={thumbnailUrl} alt="" />
        </div>
        <div className="song-info px-3 px-md-3 py-3">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h3 className="fs-4 m-0 fw-bold">{artistName}</h3>
              <p className="mb-0 fs-6">{songTitle}</p>
            </div>
            <div className="text-capitalize bg-white rounded-pill px-3 py-2 d-inline-flex bg-opacity-50"><small>{level ? level + ' / ' : ''} {subLevel}</small></div>
          </div>
        </div>
        <div className="p-3 p-md-3 d-flex">
          <div className="bttn bttn-stroke">{buttonText}</div>
        </div>
      </div>
    </div>
  );
};

export default LearnSongsCard;
