import React, { useEffect } from "react";
import { UserVideo } from "../../models/UserVideo";
import { formatDate } from "../../utility/DateFormatter";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

interface MyVideoCardProps {
  id: string;
  video: UserVideo;
  isPlaying: boolean;
  isModalView: boolean;
  onPlay: () => void;
  onTryAgainClick: (songId?: string) => void;
  onFindOpponentClick: (userVideo: UserVideo) => void;
  onDelete: (video: UserVideo) => void;
  onVideoSelected: (video: UserVideo) => void;
}

const MyVideoCard: React.FC<MyVideoCardProps> = ({
  id,
  video,
  isPlaying,
  isModalView,
  onPlay,
  onTryAgainClick,
  onFindOpponentClick,
  onDelete,
  onVideoSelected,
}) => {
  const localCreatedDate = formatDate(new Date(video.createdAt));
  const [thumbnailError, setThumbnailError] = React.useState(false);

  const reviewContent =
    video.reviewState === "pending" ? (
      <span className="d-flex flex-column gap-1 lh-sm"><span className="fw-bold fs-5">Your video is under evaluation.</span><span> Come back for feedback in 24 hours.</span></span>
    ) : (
      <span className="d-flex flex-column gap-1 lh-sm">
        <span className="fw-bold fs-5">{video.percentage} Points</span>
        {video.percentage < 85 ? (
          <span>Keep practicing to hit 80 points and join the battles!</span>
        ) : (
          <span>You can now challenge others!</span>
        )}
      </span>
    );

  const actionButton =
    isModalView ? (
      <button
        onClick={() => onVideoSelected(video)}
        className="bttn bttn-stroke align-self-end"
      >
        Select
      </button>
    ) :
      video.percentage < 85 ? (
        <button
          onClick={() => onDelete(video)}
          className="bttn bttn-stroke align-self-end"
        >
          Delete
        </button>
      ) : (
        <button
          onClick={() => onFindOpponentClick(video)}
          className="bttn bttn-stroke align-self-start"
          disabled={video.percentage < 85}
        >
          Go battle
        </button>
      );

  useEffect(() => {
    console.log("Thumbnail URL: ", video?.thumbnailUrl);
    if (video?.thumbnailUrl) {
      axios.get(video.thumbnailUrl)
        .then(() => {
          console.log("Thumbnail loaded successfully");
          setThumbnailError(false);
        })
        .catch(() => {
          console.log("Thumbnail failed to load");
          setThumbnailError(true);
        });
    } else {
      setThumbnailError(true);
    }
  }, [video?.thumbnailUrl]);

  return (
    <div className="card-songs card-songs-listing d-flex flex-column h-100 rounded-5">
      <div
        className="song-image position-relative"
        style={{ position: "relative" }}
      >
        {isPlaying ? (
          <AWSVideoPlayerForm
            videoUrl={video.videoUrl}
            thumbnailUrl={video.thumbnailUrl}
            isPrivate={true}
            autoplay={true}
          />
        ) : (
          thumbnailError ? (
            <div className="d-flex flex-column w-100 h-100 bg-black p-5 text-white align-items-center justify-content-center text-center">
              <p className="fs-4 fw-bold ">Your video is being processed and will be ready in just a few minutes. </p>
              <p>You'll be able to watch it soon—hang tight!</p>
            </div>
          ) :
            <>
              <img
                crossOrigin="anonymous"
                src={video?.thumbnailUrl}
                style={{ width: "100%", height: "auto" }}
              />
              <button
                onClick={onPlay}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "60px",
                  height: "60px",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  border: "none",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  zIndex: 10,
                }}
              >
                <FontAwesomeIcon icon={faPlay} size="2x" color="white" />
              </button>
            </>
        )}
      </div>
      <div className="song-info px-3 px-md-3 py-3 mt-0">
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h3 className="fs-4 m-0 fw-bold">
              {video.song?.artistName || " "}
            </h3>
            <p className="mb-0 fs-6">{video.song?.songTitle || " "}</p>
          </div>
        </div>
      </div>
      <div className="p-3 p-md-3 d-flex justify-content-between align-items-end w-100 h-100">
        {reviewContent}
        {actionButton}
      </div>
    </div>
  );
};

export default MyVideoCard;
